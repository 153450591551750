<template>
  <div>
    <v-card max-width="300px" class="shadow-lg rounded-lg">
      <v-card-text class="pa-3">
        <UserPhoto :id="userid" size="100%" tile photoSize="small" />
      </v-card-text>

      <preloader v-if="!profile" />
      
      <!-- <v-card-title class="d-flex flex-column pa-4 pt-7">
        <div class="d-flex profile-social-divider">
          <v-divider class="my-5"></v-divider>
        </div>
      </v-card-title> -->

      <v-card-subtitle v-if="profile" class="d-flex flex-column">
        <div class="mb-4">
          <a
            class="text-decoration-none font-weight-medium primary--text primary-link d-flex align-center"
            :href="`mailto:${$store.getters['users/getEmail'](userid)}`"
          >
            <v-icon color="primary" size="20" class="mr-2">
              mdi-email-outline
            </v-icon>
            <v-hover v-slot="{ hover }">
              <span 
                style="display: inline; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                :class="{ 'on-hover text-decoration-underline': hover }"
              >
                {{ $store.getters["users/getEmail"](userid) }}
              </span>
            </v-hover>
          </a>
        </div>
        <div class="mb-4">
          <a
            :href="`tel:${profile.phone}`"
            class="text-decoration-none font-weight-medium primary--text primary-link d-flex align-center"
          >
            <v-icon color="primary" size="20" class="mr-2">
              mdi-phone-dial-outline
            </v-icon>
            <v-hover v-slot="{ hover }">
              <span :class="{ 'on-hover text-decoration-underline': hover }">
                {{ profile.phone }}
              </span>
            </v-hover>
          </a>
        </div>
        <div class="mb-4">
          <a
            :href="'//' + profile.website"
            class="text-decoration-none grey--text"
            target="_blank"
            v-if="profile.website"
          >
            <v-icon class="mr-2"> mdi-web </v-icon>
            {{ profile.website }}
          </a>
        </div>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "SideBarProfile",

  metaInfo: {
    title: "SideBarProfile",
  },
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    userid: String,
  },

  data() {
    return {}
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("profile", ["getProfile", "getBackgroundPhotoUrl"]),
    ...mapActions("users", ["getTalentAccount"]),

    viewAvailability(user) {
      console.log(user)
    },
  },

  created() {
    this.getProfile(this.userid)
  },
}
</script>