<template>
  <div class="flex-grow-1">
    <div  class="mb-12">
      <div>
          <v-btn
            :to="{ name: 'TalentProfile', params: { id: $route.params.id } }"
            class="mb-3"
            v-if="user"  
            outlined
            small
          >
            <v-icon left small>mdi-chevron-left</v-icon>
            Return to candidate profile
          </v-btn>
          <h2 class="text-h3 font-weight-black">
            {{ user.role == 'employer' ? `${teacher.firstName}'s` : 'Your' }} Availability
          </h2>
      </div>
      <v-divider class="mb-5 mt-5"></v-divider>
    </div>

    <v-row class="fill-height">
      <v-col cols="2">
        <div>
          <SideBarProfile :userid="this.$route.params.id"/>
        </div>
      </v-col>

      <v-col cols="10">
        <preloader v-if="!available" />
        <v-card class="shadow-lg">
          <v-card-text>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                v-if="events && user"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="getEvents"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" flat>
                  <v-toolbar
                    :color="selectedEvent.color"
                    class="pl-4 pr-4"
                    dark
                  >
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>
                  </v-toolbar>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideBarProfile from "@/components/availability/SideBarProfile"
import { mapState, mapActions } from "vuex"
import firebase from 'firebase'

export default {
  name: "UserAvailability",

  metaInfo: {
    title: "UserAvailability",
  },

  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      thisEventData: {
        id: null,
        triggered: false,
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "deep-orange",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
    }
  },

  components: {
    SideBarProfile,
  },
  
  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      users: (state) => state.users.users,
      status: (state) => state.availability.status,
      available: (state) => state.availability.userAvailability,
      jobseekerAvailability: (state) => state.availability.setJobSeekerAvailability,
      changedEvents: (state) => state.availability.availability,
    }),

    teacher: function () {
      let current = firebase.auth().currentUser
      let currentUser = this.$route.params.id || current.uid
      return this.users.find(user => user.userid == currentUser) || {}
    }
  },
  
  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("availability", [
      "getUserAvailability",
      "getJobseekerAvailability",
    ]),

    viewDay({ date }) {
      this.focus = date
      // this.type = "day"
    },
    
    getEventColor(event) {
      return event.color
    },
    
    setToday() {
      this.focus = ""
    },
    
    prev() {
      this.$refs.calendar.prev()
    },
    
    next() {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = {}
        this.selectedElement = null
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    getEvents() {
      this.events = []
      const events = []

      Promise.all([this.getUserAvailability(this.$route.params.id)]).then(
        () => {
          this.available.forEach(function (date) {

            events.push({
              name: date.display,
              start: date.startDate,
              end: date.endDate,
              id: date.id,
              userid: date.userid,
              startDate: date.startDate,
              startHr: date.startHr,
              startMin: date.startMin,
              endDate: date.endDate,
              endHr: date.endHr,
              endMin: date.endMin,
              color: date.color,
              timed: false,
            })


          })
        }
      )

      this.events = events
    },
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
      this.$store.dispatch('users/getUserByUid', this.$route.params.id)
  },
}
</script>